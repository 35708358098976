import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  TextField,
} from "@mui/material";
import { useCore } from "components/core-sub/context";
import { LMSAccount } from "components/core-sub/Controller/lms.account";

export const Selection = (props: SelectProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select {...props} />
    </FormControl>
  );
};

export type THNameProps = {
  account: LMSAccount;
  onChange: (field: keyof LMSAccount, expanded?: string) => (e: any) => void;
};
export const THName = ({ account, onChange }: THNameProps) => {
  const { t } = useCore();

  const isOther = () => !["นาย", "นาง", "นางสาว"].includes(account.thtitle);

  return (
    <>
      <Grid item xs={12} sm={isOther() ? 6 : 12}>
        <Selection
          label={t("NameTitle") + " (TH)"}
          value={isOther() ? "อื่นๆ" : account.thtitle}
          onChange={onChange("thtitle", "name")}
        >
          <MenuItem value="">
            -- {t("Select $Name", { name: t("NameTitle") })} --
          </MenuItem>
          <MenuItem value="นาย">นาย</MenuItem>
          <MenuItem value="นาง">นาง</MenuItem>
          <MenuItem value="นางสาว">นางสาว</MenuItem>
          <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
        </Selection>
      </Grid>
      {isOther() && (
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("Specify Others")}
            value={account.thtitle}
            onChange={onChange("thtitle", "name")}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={t("First Name") + " (TH)"}
          value={account.thfirstname}
          onChange={onChange("thfirstname", "name")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={t("Middle Name") + " (TH)"}
          value={account.thmiddlename}
          onChange={onChange("thmiddlename", "name")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("Last Name") + " (TH)"}
          value={account.thlastname}
          onChange={onChange("thlastname", "name")}
        />
      </Grid>
    </>
  );
};
