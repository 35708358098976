import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Dialog,
  Grid,
  MenuItem,
  Slide,
  SlideProps,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import ActionIcon from "components/core-sub/ActionIcon";
import { useAlerts } from "components/core-sub/Alerts";
import { Container } from "components/core-sub/Container";
import { ContentHeader } from "components/core-sub/ContentHeader";
import { useCore } from "components/core-sub/context";
import { LMSAccount } from "components/core-sub/Controller/lms.account";
import { KuiButton } from "components/core-sub/KuiButton";
import { PickIcon } from "components/core-sub/PickIcon";
import React, { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { ENName } from "./enname";
import { Selection, THName } from "./thname";

const AccordionStyled = (props: {
  expanded?: boolean;
  complete: boolean;
  summary?: ReactNode;
  children?: ReactNode;
  action?: ReactNode;
  onChange?: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
}) => {
  return (
    <Accordion
      expanded={!props.complete || props.expanded}
      onChange={props.onChange}
    >
      <AccordionSummary expandIcon={<PickIcon icon="chevron-down" />}>
        <Typography>
          <PickIcon icon={props.complete ? "check-circle" : "circle"} />{" "}
          {props.summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>{props.children}</Stack>
      </AccordionDetails>
      {props.action && <AccordionActions>{props.action}</AccordionActions>}
    </Accordion>
  );
};

export const StudentInfo = React.memo(() => {
  const { user, t, open, setOpen } = useCore();
  const [state, setState] = useState<{
    loading: boolean;
    data: null | LMSAccount;
    expanded: string;
  }>({
    loading: true,
    data: null,
    expanded: "",
  });
  const { addAlert } = useAlerts();

  useEffect(() => {
    if (user.loading === false && user.data && Boolean(open.information)) {
      LMSAccount.get(user.data).then((data) =>
        setState((s) => ({ ...s, loading: false, data }))
      );
    }
  }, [user, open.information]);

  const handleClose = () => setOpen((s) => ({ ...s, information: false }));
  const handleExpand = (expanded: string) => () =>
    setState((s) => ({
      ...s,
      expanded: s.expanded === expanded ? "" : expanded,
    }));
  const handleChange =
    <Key extends keyof LMSAccount>(field: Key, expanded?: string) =>
    ({ target: { value } }: any) => {
      setState((s) => ({
        ...s,
        data: s.data?.update(field, String(value) as LMSAccount[Key]) ?? null,
        expanded: expanded || s.expanded,
      }));
    };
  const handleSave = async () => {
    if (state.data && user.data) {
      await state.data.save(user.data);
      handleClose();
      addAlert({ label: t("Saved") });
    }
  };

  return (
    <Dialog
      fullScreen
      open={Boolean(open.information)}
      onClose={handleClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "down" } as SlideProps}
    >
      <AppBar
        variant="outlined"
        position="relative"
        elevation={0}
        sx={{ color: "text.primary", backgroundColor: "background.paper" }}
      >
        <Toolbar>
          <ActionIcon
            edge="start"
            size="large"
            icon="xmark"
            color="inherit"
            onClick={handleClose}
          />
          <Box flex={1} />
          <KuiButton
            tx="save"
            onClick={handleSave}
            disabled={!Boolean(state.data?.isComplete())}
          />
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        {state.data && (
          <Box py={6}>
            <ContentHeader
              label={t("Edit $Name", { name: t("Information") })}
            />
            <AccordionStyled
              complete={Boolean(state.data?.occupation)}
              expanded={state.expanded === "occupation"}
              summary={t("Occupation")}
              onChange={handleExpand("occupation")}
            >
              <Selection
                label={t("Occupation")}
                value={state.data?.occupation || ""}
                onChange={handleChange("occupation", "occupation")}
              >
                <MenuItem value="">
                  -- {t("Select $Name", { name: t("Occupation") })} --
                </MenuItem>
                <MenuItem value="student">{t("Student")}</MenuItem>
                <MenuItem value="teacher">{t("Teacher")}</MenuItem>
                <MenuItem value="others">{t("Others")}</MenuItem>
              </Selection>
            </AccordionStyled>
            {state.data?.occupation && (
              <>
                {["student", "teacher"].includes(state.data.occupation) && (
                  <AccordionStyled
                    complete={Boolean(
                      state.data.institute && state.data.institutename
                    )}
                    expanded={state.expanded === "institute"}
                    summary={t("Institute")}
                    onChange={handleExpand("institute")}
                  >
                    <Selection
                      label={t("Institute")}
                      value={state.data?.institute || ""}
                      onChange={handleChange("institute", "institute")}
                    >
                      <MenuItem value="">
                        -- {t("Select $Name", { name: t("Institute") })} --
                      </MenuItem>
                      <MenuItem value="school">{t("School")}</MenuItem>
                      <MenuItem value="university">{t("University")}</MenuItem>
                      <MenuItem value="others">{t("Others")}</MenuItem>
                    </Selection>
                    <TextField
                      fullWidth
                      label={t("$Name Name", { name: t("Institute") })}
                      value={state.data.institutename || ""}
                      onChange={handleChange("institutename", "institute")}
                    />
                  </AccordionStyled>
                )}
                {state.data.occupation === "others" && (
                  <AccordionStyled
                    complete={Boolean(
                      state.data.workplace && state.data.jobtitle
                    )}
                    expanded={state.expanded === "work"}
                    summary={t("Work")}
                    onChange={handleExpand("work")}
                  >
                    <TextField
                      label={t("Workplace")}
                      value={state.data.workplace}
                      onChange={handleChange("workplace", "work")}
                    />
                    <TextField
                      label={t("Job Title")}
                      value={state.data.jobtitle}
                      onChange={handleChange("jobtitle", "work")}
                    />
                  </AccordionStyled>
                )}
                {state.data.isJobComplete() && (
                  <>
                    <AccordionStyled
                      summary={t("Name")}
                      expanded={state.expanded === "name"}
                      complete={state.data.isNameComplete()}
                      onChange={handleExpand("name")}
                    >
                      <Grid container spacing={2}>
                        <THName account={state.data} onChange={handleChange} />
                        <ENName account={state.data} onChange={handleChange} />
                      </Grid>
                    </AccordionStyled>
                  </>
                )}
                {state.data.isNameComplete() && (
                  <AccordionStyled
                    expanded={state.expanded === "contact"}
                    complete={Boolean(state.data.email)}
                    summary={t("Others")}
                    onChange={handleExpand("contact")}
                  >
                    <TextField
                      label={t("Student ID")}
                      value={state.data.studentId}
                      onChange={handleChange("studentId", "institute")}
                    />
                    <TextField
                      fullWidth
                      label={t("Telephone")}
                      value={state.data.telephone}
                      onChange={handleChange("telephone")}
                    />
                    <TextField
                      fullWidth
                      label={t("Email")}
                      value={state.data.email}
                      InputProps={{ readOnly: true }}
                    />
                    <TextField
                      fullWidth
                      label={"Line ID"}
                      value={state.data.lineid}
                      onChange={handleChange("lineid")}
                    />
                  </AccordionStyled>
                )}
              </>
            )}
          </Box>
        )}
      </Container>
    </Dialog>
  );
});
