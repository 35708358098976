import { db } from "components/core-sub/Controller/firebase";
import { User } from "firebase/auth";
import {
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

export class Consent {
  master: boolean;
  submit: boolean;

  constructor(data?: Partial<Consent>) {
    this.master = data?.master ?? false;
    this.submit = data?.submit ?? false;
  }

  set<T extends keyof Omit<this, "submit">>(field: T, value: this[T]): this {
    this[field] = value;
    return this;
  }

  async save(user: User, host: string) {
    const { master } = this;
    await setDoc(
      doc(db, "users", user.uid, "consents", host),
      { master, submit: true, date: serverTimestamp() },
      { merge: true }
    );
  }

  static watch(
    user: User,
    host: string,
    callback: (data: Consent) => void
  ): Unsubscribe {
    return onSnapshot(
      doc(db, "users", user.uid, "consents", host),
      (snapshot) => {
        callback(new Consent(snapshot.data()));
      }
    );
  }
}
