import { Button, Stack, Typography } from "@mui/material";
import { useCore } from "components/core-sub/context";
import { auth } from "components/core-sub/Controller/firebase";
import { DialogCompact } from "components/core-sub/DialogCompact";
import { Loading } from "components/core-sub/Loading";
import { SignIn } from "components/core-sub/SignIn";
import { signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Consent } from "./consent";

const provider = process.env.REACT_APP_COOKIE_CONSENT_PROVIDER;
const prefix = process.env.REACT_APP_PREFIX;

export const CookieConsent = (props: { children?: React.ReactNode }) => {
  const { user } = useCore();
  const [state, setState] = useState<{
    loading: boolean;
    data: Consent;
  }>({
    loading: true,
    data: new Consent(),
  });

  const handleConfirm = async () => {
    if (user.data && prefix) {
      const data = new Consent({ submit: true, master: true });
      await data.save(user.data, prefix);
    }
  };
  const handleSignOut = async () => {
    await signOut(auth);
  };

  useEffect(() => {
    if (user.loading === false && user.data && provider && prefix) {
      return Consent.watch(user.data, prefix, (data) => {
        setState((s) => ({ ...s, loading: false, data }));
      });
    }
  }, [user]);

  if (user.loading) {
    return <Loading />;
  } else {
    if (!user.data) {
      return <SignIn />;
    } else {
      if (provider) {
        return state.loading ? (
          <Loading />
        ) : state.data.submit && state.data.master ? (
          <>{props.children}</>
        ) : (
          <>
            <DialogCompact
              open={state.loading === false && state.data.submit === false}
              maxWidth="sm"
              icon="exclamation-triangle"
              title="ข้อกำหนดและเงื่อนไข"
              hideCloseButton
              actions={
                <>
                  <Button color="primary" onClick={handleConfirm}>
                    ยอมรับ
                  </Button>
                  <Button color="neutral" onClick={handleSignOut}>
                    ลงชื่อออก
                  </Button>
                </>
              }
              componentProps={{ dialogActions: { sx: { pr: 3 } } }}
            >
              <Stack spacing={2}>
                <Typography>
                  ข้อกำหนดและเงื่อนไขฉบับนี้ ถือเป็นข้อตกลงระหว่าง {provider}{" "}
                  ในฐานะ "ผู้ให้บริการ" กับ "ข้าพเจ้า" ในฐานะผู้รับบริการ
                </Typography>
                <Typography>
                  ข้าพเจ้ายินยอมให้ผู้ให้บริการเก็บรวบรวม ใช้
                  และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า
                  เพื่อวัตถุประสงค์ในการดำเนินกิจกรรมของหน่วยงาน
                  รวมถึงวัตถุประสงค์อื่นๆ ตามที่ผู้ให้บริการเห็นสมควร
                </Typography>
                <Typography>
                  ข้าพเจ้ารับทราบว่า
                  หากข้าพเจ้าไม่ตกลงยอมรับข้อกำหนดและเงื่อนไขนี้
                  ผู้ให้บริการจะสงวนสิทธิไม่ให้บริการแก่ข้าพเจ้าได้
                </Typography>
              </Stack>
            </DialogCompact>
          </>
        );
      } else {
        return <>{props.children}</>;
      }
    }
  }
};
