import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  TextField,
} from "@mui/material";
import { useCore } from "components/core-sub/context";
import { LMSAccount } from "components/core-sub/Controller/lms.account";

export const Selection = (props: SelectProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select {...props} />
    </FormControl>
  );
};

export type ENNameProps = {
  account: LMSAccount;
  onChange: (field: keyof LMSAccount, expanded?: string) => (e: any) => void;
};
export const ENName = ({ account, onChange }: ENNameProps) => {
  const { t } = useCore();

  const isOther = () => !["Mr.", "Mrs.", "Miss"].includes(account.entitle);

  return (
    <>
      <Grid item xs={12} sm={isOther() ? 6 : 12}>
        <Selection
          label={t("NameTitle") + " (EN)"}
          value={isOther() ? "other" : account.entitle}
          onChange={onChange("entitle", "name")}
        >
          <MenuItem value="">
            -- {t("Select $Name", { name: t("NameTitle") })} --
          </MenuItem>
          <MenuItem value="Mr.">Mr.</MenuItem>
          <MenuItem value="Mrs.">Mrs.</MenuItem>
          <MenuItem value="Miss">Miss</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Selection>
      </Grid>
      {isOther() && (
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t("Specify Others")}
            value={account.entitle}
            onChange={onChange("entitle", "name")}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={t("First Name") + " (EN)"}
          value={account.enfirstname}
          onChange={onChange("enfirstname", "name")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={t("Middle Name") + " (EN)"}
          value={account.enmiddlename}
          onChange={onChange("enmiddlename", "name")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("Last Name") + " (EN)"}
          value={account.enlastname}
          onChange={onChange("enlastname", "name")}
        />
      </Grid>
    </>
  );
};
