import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CoreProvider, { useCore } from "components/core-sub/context";
import { lazy, Suspense } from "react";
import { Loading } from "components/core-sub/Loading";
import { StudentInfo } from "components/student.info";
import { ProfileMenu } from "components/profile.menu";
import { CookieConsent } from "components/CookieConsent";
import { app } from "components/core-sub/Controller/firebase";

const PageLanding = lazy(() => import("./pages/landing"));
const PageHome = lazy(() => import("./pages/home"));
const PageCourse = lazy(() => import("./pages/course"));
const PageQuiz = lazy(() => import("pages/quiz"));
const PageCheckIn = lazy(() => import("pages/checkin"));

const Routing = () => {
  const { user } = useCore();

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/checkin/:sectionId/:checkInId/"
            element={<PageCheckIn />}
          />
          <Route
            path="/course/*"
            element={
              <CookieConsent>
                <Routes>
                  <Route
                    path="/:courseId/quiz/:itemId"
                    element={<PageQuiz />}
                  />
                  <Route
                    path="/:courseId/assignment/:itemId"
                    element={<PageCourse />}
                  />
                  <Route
                    path="/:courseId/lesson/:itemId"
                    element={<PageCourse />}
                  />
                  <Route path="/:courseId" element={<PageCourse />} />
                  <Route path="/" element={<PageHome />} />
                </Routes>
              </CookieConsent>
            }
          />
          {user.data ? (
            <Route path="/*" element={<PageHome />} />
          ) : (
            <Route path="/*" element={<PageLanding />} />
          )}
        </Routes>
        <StudentInfo />
      </Suspense>
    </BrowserRouter>
  );
};

function App() {
  return (
    <CoreProvider
      firebaseApp={app}
      logo={process.env.REACT_APP_LOGO}
      profileMenu={<ProfileMenu />}
    >
      <Routing />
    </CoreProvider>
  );
}

export default App;
