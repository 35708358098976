import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useCore } from "components/core-sub/context";
import { PickIcon } from "components/core-sub/PickIcon";
// import { useNavigate } from "react-router-dom";

export const ProfileMenu = () => {
  const { t, setOpen } = useCore();
  // const nav = useNavigate()

  return (
    <>
      {/* <ListItemButton divider onClick={() => nav('/course')}>
        <ListItemIcon>
          <PickIcon icon="chalkboard" />
        </ListItemIcon>
        <ListItemText primary={t("My $Name", { name: t("Course") })} />
      </ListItemButton> */}
      <ListItemButton
        divider
        onClick={() => setOpen((s) => ({ ...s, information: true }))}
      >
        <ListItemIcon>
          <PickIcon icon="user-edit" />
        </ListItemIcon>
        <ListItemText primary={t("Edit $Name", { name: t("Information") })} />
      </ListItemButton>
    </>
  );
};
